import React from 'react';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';
import Helmet from 'react-helmet';

export class LayoutHelmet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.navState = '';
	}
	componentDidMount() {
		this.state.navState = this.props.passedAppFunctions.navState;
		this.updateDataLayer();
	}
	componentDidUpdate(prevProps, nextState) {
		//compare prevProps to this.props
		if (this.props.contentData.navState && this.state.navState != this.props.contentData.navState) {
			this.state.navState = this.props.contentData.navState;
			this.updateDataLayer();
		}
	}
	shouldComponentUpdate(nextProps) {
		return !_.isEqual(this.props, nextProps);
	}

	updateDataLayer() {
		var pageObject = {};
		this.state.isRealSearch = window.isRealSearch;
		if (this.state.isRealSearch) {
			try {
				this.state.isRealSearch = this.state.isRealSearch.trim();
			} catch (e) {}
			window.isRealSearch = false;
		}
		try {
			pageObject.name = (this.props.contentData.seo.title || '').split(" | Dillard's").join('');
			//determine which page group to use
			if (this.props.contentData.seo.adobeTrackingPageType == 'search') {
				//search
				pageObject.type = 'search';
				pageObject.RealSearch = this.state.isRealSearch ? 'Y' : 'N';
				pageObject.categoryId = '-10005';
				pageObject.pageId = this.props.contentData.seo.totalCount > 0 ? 'SEARCH: Results Successful' : 'SEARCH: Results UnSuccessful';
				pageObject.primaryCategory = '';
				pageObject.subCategory1 = '';
				pageObject.subCategory2 = '';
				pageObject.searchPagination = this.props.passedAppFunctions.getURLParamValue('pageNumber')
					? this.props.passedAppFunctions.getURLParamValue('pageNumber')
					: '1';
				if (pageObject.name == '' && this.props.contentData.parentFile == 'SearchResultsDisplay') {
					pageObject.name = 'Social Style';
				}
				if (pageObject.RealSearch == 'Y') {
					pageObject.searchResultsCount = this.props.contentData.seo.totalCount;
					pageObject.searchTerm = this.props.contentData.seo.searchTerm.split('+').join(' ');
				}
			} else if (this.props.contentData.seo.adobeTrackingPageType == 'category') {
				//category
				pageObject.type = 'category';

				if (this.props.passedAppFunctions.getURLParamValue('trackAsSearch')) {
					pageObject.pageId = 'SEARCH: Results Successful';
					pageObject.categoryId = '-10005';
					pageObject.RealSearch = 'Y';
					pageObject.searchResultsCount = this.props.contentData.seo.totalCount;
					pageObject.searchTerm = decodeURIComponent(this.props.passedAppFunctions.getURLParamValue('trackAsSearch'))
						.split('+')
						.join(' ');
					pageObject.searchTerm = pageObject.searchTerm.split('+').join(' ');
					pageObject.primaryCategory = '';
					pageObject.subCategory1 = '';
					pageObject.subCategory2 = '';
					pageObject.searchPagination = this.props.passedAppFunctions.getURLParamValue('pageNumber')
						? this.props.passedAppFunctions.getURLParamValue('pageNumber')
						: '1';
				} else {
					pageObject.pageId =
						this.props.contentData.seo.adobeTrackingTrackingJson && this.props.contentData.seo.adobeTrackingTrackingJson.page
							? this.props.contentData.seo.adobeTrackingTrackingJson.page.pageId
							: '';
					pageObject.categoryId = this.props.contentData.seo.category;
					pageObject.RealSearch = 'N';
				}
				for (var i = 1; i <= 9; i++) {
					// for loop for slots
					//add the fallback because chanel nav template only has 5 slots, so pretend it has slots 6-9 without any widgets in them
					var thisSlotData = this.props.contentData.layoutContent.slots[i + ''] || {"widgets":[]};
					for (var j = 0; j < thisSlotData.widgets.length; j++) {
						//loop for widgets to find Breadcrumps
						var thisWidget = thisSlotData.widgets[j];
						if (thisWidget.widgetType == 'BreadcrumbTrail') {
							if (thisWidget.breadcrumbItems.length > 0) {
								var primaryCategory = thisWidget.breadcrumbItems[0].breadcrumbValue;
								pageObject.primaryCategory = primaryCategory
									.split('&#039;')
									.join("'")
									.split('&amp;')
									.join('&');
								pageObject.primaryCategoryId = thisWidget.breadcrumbItems[0].breadcrumbCatId;
							} else {
								pageObject.primaryCategory = ' ';
								pageObject.primaryCategoryId = '';
							}
							if (thisWidget.breadcrumbItems.length > 1) {
								pageObject.subCategory1 = thisWidget.breadcrumbItems[1].breadcrumbValue
									.split('&#039;')
									.join("'")
									.split('&amp;')
									.join('&');
								pageObject.subCategoryId1 = thisWidget.breadcrumbItems[1].breadcrumbCatId;
							} else {
								pageObject.subCategory1 = ' ';
								pageObject.subCategoryId1 = ' ';
							}

							if (thisWidget.breadcrumbItems.length > 2) {
								pageObject.subCategory2 = thisWidget.breadcrumbItems[2].breadcrumbValue
									.split('&#039;')
									.join("'")
									.split('&amp;')
									.join('&');
								pageObject.subCategoryId2 = thisWidget.breadcrumbItems[2].breadcrumbCatId;
							} else {
								pageObject.subCategory2 = ' ';
								pageObject.subCategoryId2 = ' ';
							}
						}
					}
				}
			} else if (this.props.contentData.seo.adobeTrackingPageType == 'brand') {
				//category
				pageObject.type = 'brand';

				if (this.props.passedAppFunctions.getURLParamValue('trackAsSearch')) {
					pageObject.pageId = 'SEARCH: Results Successful';
					pageObject.categoryId = '-10005';
					pageObject.RealSearch = 'Y';
					pageObject.searchResultsCount = this.props.contentData.seo.totalCount;
					pageObject.searchTerm = decodeURIComponent(this.props.passedAppFunctions.getURLParamValue('trackAsSearch'));
					pageObject.searchTerm = pageObject.searchTerm.split('+').join(' ');
				} else {
					pageObject.pageId =
						this.props.contentData.seo.adobeTrackingTrackingJson && this.props.contentData.seo.adobeTrackingTrackingJson.page
							? this.props.contentData.seo.adobeTrackingTrackingJson.page.pageId
							: '';
					pageObject.categoryId = '-10032';
					pageObject.RealSearch = 'N';
				}
				pageObject.searchPagination = this.props.passedAppFunctions.getURLParamValue('pageNumber')
					? this.props.passedAppFunctions.getURLParamValue('pageNumber')
					: '1';
				pageObject.primaryCategory = 'brand';
				pageObject.subCategory1 = '';
				pageObject.subCategory2 = '';
			} else if (this.props.contentData.parentFile == 'StaticContentPageDisplay') {
				//content
				pageObject.type = 'content';
				
				pageObject.categoryId = '-10010';
				if (pageObject.name === 'Clearance') {
					pageObject.name = 'Price Reductions 65Off';
					pageObject.pageId = 'Content: Price Reduction 65Off';
				} else if (pageObject.name === 'Shop By Brand') {
					pageObject.type = 'shop by brand'
				} else {
					pageObject.pageId = 'Content: ' + pageObject.name;
				}
				pageObject.primaryCategory = 'Content';
				pageObject.subCategory1 = '';
				pageObject.subCategory2 = '';

				if (this.props.passedAppFunctions.getURLParamValue('trackAsSearch')) {
					pageObject.pageId = 'SEARCH: Results Successful';
					pageObject.categoryId = '-10005';
					pageObject.RealSearch = 'Y';
					pageObject.searchTerm = decodeURIComponent(this.props.passedAppFunctions.getURLParamValue('trackAsSearch'));
					pageObject.primaryCategory = 'category';
					pageObject.subCategory1 = '';
					pageObject.subCategory2 = '';
					pageObject.searchPagination = this.props.passedAppFunctions.getURLParamValue('pageNumber')
						? this.props.passedAppFunctions.getURLParamValue('pageNumber')
						: '1';
				} else {
					pageObject.RealSearch = 'N';
				}
				//special case for syndeca pages.  functions only exists if on syndeca catalog
				if (window.receiveSyndecaMessage) {
					pageObject.categoryId = '-10048';
				}
			} else if (this.props.contentData.parentFile == 'Registry') {
				//registry
				pageObject.type = 'registry';
				pageObject.categoryId = '-10024';
				pageObject.RealSearch = 'N';
				pageObject.pageId = pageObject.name.split("Dillard's ").join('Registry: ');
				pageObject.primaryCategory = 'Registry';
			} else if (this.props.contentData.parentFile == 'TopCategoriesDisplay') {
				//home
				pageObject.type = 'home';
				pageObject.pageId = 'Home Page';
				pageObject.categoryId = '-10005';
				pageObject.RealSearch = 'N';
				pageObject.primaryCategory = 'Home';
				pageObject.subCategory1 = '';
				pageObject.subCategory2 = '';
			} else {
				pageObject.type = 'default';
			}

			if (this.props.passedAppFunctions.seoColorInfo.seoColorString && this.props.passedAppFunctions.seoColorInfo.seoColorString != '') {
				pageObject.seoColor = this.props.passedAppFunctions.seoColorInfo.seoColorString;
			}

			if (this.props.contentData.seo.ubRequestId) {
				pageObject.ubRequestId = this.props.contentData.seo.ubRequestId;
			}
 
		 	if (this.props.passedAppFunctions.linkToState.typeAheadClicked && this.props.passedAppFunctions.linkToState.typeAheadClicked == 'Y') {
				pageObject.typeAheadClicked = this.props.passedAppFunctions.linkToState.typeAheadClicked;
			} else {
				if(sessionStorage.getItem('typeAheadClicked')){		
		 			pageObject.typeAheadClicked = sessionStorage.getItem('typeAheadClicked');
			 	}
				else{
					pageObject.typeAheadClicked = 'N';
				}
			}
			if(sessionStorage.getItem('typeAheadClicked')){		
			    sessionStorage.removeItem('typeAheadClicked')
		   }
		} catch (e) {
			if (window.console && window.console.log) console.log(e);
		}
		this.props.passedAppFunctions.setDataLayerInfo('page', pageObject);
	}

	render() {
		var helmetProperties = {};
		helmetProperties.meta = [];
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogTitle) {
			helmetProperties.meta.push({ property: 'og:title', content: this.props.contentData.seo.ogTitle.split('#double;').join('"') });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogDescription) {
			helmetProperties.meta.push({ property: 'og:description', content: this.props.contentData.seo.ogDescription.split('#double;').join('"') });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogImage) {
			helmetProperties.meta.push({ property: 'og:image', content: this.props.contentData.seo.ogImage });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.description) {
			helmetProperties.meta.push({ name: 'description', content: this.props.contentData.seo.description.split('#double;').join('"') });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.title) {
			helmetProperties.title = this.props.contentData.seo.title.split('#double;').join('"');
			if (this.props.passedAppFunctions.navState.indexOf('limited-availability') != -1) {				
				if (helmetProperties.title.indexOf('Limited Availability') == -1) {
					helmetProperties.title = 'Limited Availability ' + helmetProperties.title;
				}								
			}			
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.keywords) {
			helmetProperties.meta.push({ name: 'keywords', content: this.props.contentData.seo.keywords.split('#double;').join('"') });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.noindex) {
			helmetProperties.meta.push({ name: 'robots', content: 'noindex' });
		}
		if (this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.canonical) {
			var hrefCanonical = '';
			if (this.props.passedAppFunctions.navState.indexOf('pageNumber=') != -1)
				hrefCanonical = 'https://' + this.props.passedAppFunctions.wasParms.domainName + this.props.passedAppFunctions.navState;
			else hrefCanonical = this.props.contentData.seo.canonical;
			helmetProperties.link = [{ rel: 'canonical', href: hrefCanonical.replace('&#039;', "'") }];
		}

		var homePageSchema = {
			'@context': 'http://schema.org/',
			'@type': 'Organization',
			name: "Dillard's",
			sameAs: ['https://www.facebook.com/Dillards/', 'https://twitter.com/dillards', 'https://www.instagram.com/dillards/', 'https://en.wikipedia.org/wiki/Dillard%27s'],
			url: 'https://www.dillards.com/',
			contactPoint: [
				{
					'@type': 'ContactPoint',
					telephone: '+1-800-345-5273',
					contactType: 'customer service',
					contactOption: 'TollFree',
					areaServed: 'US',
				},
			],
		};
		return (
			<div className='hidden LayoutHelmet'>
				<If test={this.props.contentData.parentFile == 'TopCategoriesDisplay'}>
					<script type='application/ld+json' suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: JSON.stringify(homePageSchema) }}></script>
				</If>
				<Helmet {...helmetProperties} />
				{/* <Helmet>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogTitle}>
                        <meta property="og:title" content={this.props.contentData.seo.ogTitle.split('#double;').join('"')} />
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogDescription}>
                        <meta property="og:description" content={this.props.contentData.seo.ogDescription.split('#double;').join('"')} />
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.ogImage}>
                        <meta property="og:image" content={this.props.contentData.seo.ogImage} />
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.description}>
                        <meta name="description" content={this.props.contentData.seo.description.split('#double;').join('"').split('&#039;').join(`'`).split('&amp;').join('&')} />
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.title}>
                        <title>{this.props.contentData.seo.title.split('#double;').join('"')}</title>
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.keywords}>
                        <meta name="keywords" content={this.props.contentData.seo.keywords.split('#double;').join('"').split('&#039;').join(`'`)} />
                    </If>
                    <If test={this.props.contentData && this.props.contentData.seo && this.props.contentData.seo.canonical}>
                        <link rel="canonical" href={this.props.contentData.seo.canonical} />
                    </If>
                    <If test={this.props.passedAppFunctions.getURLParamValue('facet')}>
                        <meta name="robots" content="noindex" />
                    </If>
                </Helmet> */}
			</div>
		);
	}
}

export default LayoutHelmet;
