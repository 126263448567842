import React from 'react';
import { ContentTemplate } from './ContentTemplate/ContentTemplate';
import { ChanelTemplate } from './ChanelTemplate/ChanelTemplate';
import { NavigationTemplate } from './NavigationTemplate/NavigationTemplate';
import { LayoutHelmet } from '../../../Shared/Content/Layouts/Widgets/LayoutHelmet/LayoutHelmet';
import { htmlUnescape } from '../../../../../util/htmlUnescape';
// import '../../../../../styles/desktop/layouts-main.scss';

export class Layouts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
		this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
		this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
	}

	componentDidMount() {
		if (this.props.contentData.layoutContent && this.props.contentData.layoutContent.slots != undefined) {
			var slotKeysToIterate = Object.keys(this.props.contentData.layoutContent.slots);
			for (var i = 0; i < slotKeysToIterate.length; i++) {
				var widgetKeysToIterate = this.props.contentData.layoutContent.slots[slotKeysToIterate[i]];
				if (widgetKeysToIterate.widgets != undefined && widgetKeysToIterate.widgets.length > 0) {
					for (var j = 0; j < widgetKeysToIterate.widgets.length; j++) {
						if (
							widgetKeysToIterate.widgets[j] != undefined &&
							widgetKeysToIterate.widgets[j].widgetType === 'Javascript' &&
							widgetKeysToIterate.widgets[j].jsData != undefined
						) {
							var elm = htmlUnescape(widgetKeysToIterate.widgets[j].jsData);
							var jsFunc = new Function(elm);
							jsFunc();
						}
					}
				}
			}
		}
	}

	componentDidUpdate() {
		if (this.props.contentData.layoutContent && this.props.contentData.layoutContent.slots != undefined) {
			var slotKeysToIterate = Object.keys(this.props.contentData.layoutContent.slots);
			for (var i = 0; i < slotKeysToIterate.length; i++) {
				var widgetKeysToIterate = this.props.contentData.layoutContent.slots[slotKeysToIterate[i]];
				if (widgetKeysToIterate.widgets != undefined && widgetKeysToIterate.widgets.length > 0) {
					for (var j = 0; j < widgetKeysToIterate.widgets.length; j++) {
						if (
							widgetKeysToIterate.widgets[j] != undefined &&
							widgetKeysToIterate.widgets[j].widgetType === 'Javascript' &&
							widgetKeysToIterate.widgets[j].jsData != undefined
						) {
							var elm = htmlUnescape(widgetKeysToIterate.widgets[j].jsData);
							var jsFunc = new Function(elm);
							jsFunc();
						}
					}
				}
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		var returnValue = false;
		if (this.props.passedAppFunctions.isInternational() != this.state.storedIsInternational) {
			this.state.storedIsInternational = this.props.passedAppFunctions.isInternational();
			returnValue = true;
		}
		if (!_.isEqual(nextProps.passedAppFunctions.navigationToggles, this.props.passedAppFunctions.navigationToggles)) {
			returnValue = true;
		}
		if (
			!_.isEqual(nextProps.passedAppFunctions.seoColorInfo, this.props.passedAppFunctions.seoColorInfo) ||
			this.props.passedAppFunctions.getURLParamValue('facet') != this.state.storedFacet
		) {
			this.state.storedFacet = this.props.passedAppFunctions.getURLParamValue('facet');
			returnValue = true;
		}
		if (this.props.passedAppFunctions.getURLParamValue('orderBy') != this.state.storedOrderBy) {
			this.state.storedOrderBy = this.props.passedAppFunctions.getURLParamValue('orderBy');
			returnValue = true;
		}
		return returnValue || this.props.contentData.navState != nextProps.contentData.navState;
	}

	render() {
		var contentData = this.props.contentData;
		var layoutTemplate = (contentData.layoutContent && contentData.layoutContent.layoutTemplate) || 'Loading';

		return (
			<section data-layout={layoutTemplate}>
				{
					{
						ContentTemplate: (
							<ContentTemplate
								contentData={this.props.contentData}
								layoutContent={contentData.layoutContent}
								passedAppFunctions={this.props.passedAppFunctions}></ContentTemplate>
						),
						ChanelTemplate: <ChanelTemplate layoutContent={contentData.layoutContent} passedAppFunctions={this.props.passedAppFunctions}></ChanelTemplate>,
						NavigationTemplate: (
							<NavigationTemplate layoutContent={contentData.layoutContent} passedAppFunctions={this.props.passedAppFunctions}></NavigationTemplate>
						),
						Loading: <div></div>,
					}[layoutTemplate]
				}
				<LayoutHelmet passedAppFunctions={this.props.passedAppFunctions} contentData={contentData} />
			</section>
		);
	}
}

export default Layouts;
