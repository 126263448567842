import React from 'react';
import Modal from 'react-modal';
import Portal from '../../../../../UI/Portal/Portal';
import { Widgets } from '../Widgets/Widgets';
import { Link } from 'react-router-dom';
import { ProductRecs } from '../../../../../UI/ProductRecs/ProductRecs';
import { ArrowDownIcon } from '../../../../../UI/Icons/Icons';
import { generateLinkForFacetCommon } from '../../../../../../util/generateLinkForFacet';
import ProductRecommendations from '../Widgets/ProductRecommendations/ProductRecommendations';
import { resizeMap } from '../../../../../../util/imageMaps';

export class NavigationTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showFacetMenu: false,
			showSortMenu: false,
			stickySidebar: false,
		};
		this.toggleFacetMenu = this.toggleFacetMenu.bind(this);
		this.toggleSortMenu = this.toggleSortMenu.bind(this);
		this.generateLinkForFacet = this.generateLinkForFacet.bind(this);
		this.handleStickySidebar = this.handleStickySidebar.bind(this);
		this.reRunShowTarget = this.reRunShowTarget.bind(this);
	}

	generateLinkForFacet(thisFacetValue) {
		var returnLink = this.props.passedAppFunctions.routeInfo.location.pathname.split('?')[0];
		var search = this.props.passedAppFunctions.routeInfo.location.search || '';
		if (this.props.passedAppFunctions.routeInfo.location.pathname.indexOf('?') > -1) {
			search =
				'?' +
				this.props.passedAppFunctions.routeInfo.location.pathname
					.split('?')
					.splice(1)
					.join('?');
		}
		return this.props.passedAppFunctions.updateUrlWithParams(returnLink + search, { pageNumber: false, facet: thisFacetValue });
	}

	componentDidUpdate() {
		this.state.showFacetMenu ? $('body').addClass('filter-open') : $('body').removeClass('filter-open');
		this.state.showSortMenu ? $('body').addClass('sort-open') : $('body').removeClass('sort-open');
		this._unbindFacetClicks();
		this._handleFacetClick();
	}

	toggleFacetMenu() {
		this.setState({ showFacetMenu: !this.state.showFacetMenu });
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	toggleSortMenu() {
		this.setState({ showSortMenu: !this.state.showSortMenu });
	}

	componentDidMount() {
		$(window).on('reRunShowTarget', this.reRunShowTarget);
		this.setState({ stickySidebar: this.props.passedAppFunctions.showTarget('stickySidebar') });
		this.props.passedAppFunctions.lazyObserver();
		this._handleFacetClick();
		/**
		 * If content contains a carousel with a useMap on first image, initialize the map.
		 **/
		resizeMap();
		window.addEventListener('load', resizeMap); 
		window.addEventListener('resize', _.debounce(resizeMap, 400));
	}

	componentWillUnmount() {
		$('body').removeClass('filter-open');
		$('body').removeClass('sort-open');
		this._unbindFacetClicks();
		window.removeEventListener('load', this.resizeMap);
		window.removeEventListener('resize', this.resizeMap);
		$(window).off('reRunShowTarget', this.reRunShowTarget);
		if(this.props.passedAppFunctions.showTarget('stickySidebar')) {
			window.removeEventListener('scroll', this.handleStickySidebar);
		}
	}

	_handleFacetClick() {
		$('.facet-title').on('click.toggle', function(e) {
			$(this).toggleClass('open');
		});

		$('.facet li a').on('click.hideLeaf', function() {
			var el = document.getElementsByClassName('facet-title');
			for (var i = 0; i < el.length; i++) {
				if (el[i] !== null && el[i].getAttribute('data-isleaflevel') === 'true') {
					el[i].nextElementSibling.style.display = 'none';
					el[i].style.display = 'none';
				} else if (el[i] !== null && el[i].getAttribute('data-isleaflevel') === 'false') {
					el[i].nextElementSibling.style.display = 'block';
					el[i].style.display = 'block';
				}
			}
		});
	}

	_unbindFacetClicks() {
		$('.facet-title').off('click.toggle');
		$('.facet li a').off('click.hideLeaf');
	}

	handleStickySidebar() {
		const newArrivals = document.querySelector('.aside-facets-column');
		var sidebarNav = document.getElementById("sidebar");
		
		if (this.props.passedAppFunctions.siteFormat == 'desktop') {
			const initialPosition = window.pageYOffset || document.body.scrollTop;
			if (newArrivals.getBoundingClientRect().top <= 0 && initialPosition > 0) {
				if (document.querySelectorAll("sticky-sidebar").length < 0) {
					sidebarNav.classList.remove("sticky-sidebar");
				} else {
					sidebarNav.classList.add("sticky-sidebar");
			 	}
			}
		}
	}

	reRunShowTarget() {
		if (this.props.passedAppFunctions.siteFormat == 'desktop' && this.props.passedAppFunctions.showTarget('stickySidebar')) {
			this.setState({ stickySidebar: true });
			window.addEventListener('scroll', this.handleStickySidebar);
		}
	}

	render() {
		var newArrivalsWidgetIsPresent = false;
		var shopSaleWidgetIsPresent = false;
		for (var i = 1; i <= 9; i++) {
			var thisSlotData = this.props.layoutContent.slots[i + ''];
			for (var j = 0; j < thisSlotData.widgets.length; j++) {
				var thisWidget = thisSlotData.widgets[j];
				if (thisWidget.widgetType == 'ShopNewArrivals') {
					newArrivalsWidgetIsPresent = true;
				}
				if (thisWidget.widgetType == 'ShopSale') {
					shopSaleWidgetIsPresent = true;
				}
			}
		}

		var slot = this.props.layoutContent.slots;
		var containsBreadcrumbTrail = false;
		var actualBreadCrumb = '';
		var excludeFacets = false;
		for (var key in slot) {
			var thisSlot = slot[key].widgets;
			for (var i = 0; i < thisSlot.length; i++) {
				if ((thisSlot[i].widgetType != 'undefined' && thisSlot[i].widgetType == 'BreadcrumbTrail') || thisSlot[i].breadcrumbItems !== undefined) {
					containsBreadcrumbTrail = true;
					actualBreadCrumb = thisSlot[i].breadcrumbItems;
					if (thisSlot[i].breadcrumbItems.length === 1 && this.props.passedAppFunctions.contentSwitchPath.indexOf('/search-term/') >= 0) {
						excludeFacets = true;
					}
				}
			}
		}

		return (
			<section id='NavigationTemplate'>
				<div className='row template-row'>
					<div className='col-12'>
						<Widgets
							slotNumber={1}
							slotData={this.props.layoutContent.slots['1']}
							facetsJSON={this.props.layoutContent.facetsJSON}
							passedAppFunctions={this.props.passedAppFunctions}
							containsBreadcrumbTrail={containsBreadcrumbTrail}
							excludeFacets={excludeFacets}
						/>
						<Widgets
							slotNumber={2}
							slotData={this.props.layoutContent.slots['2']}
							facetsJSON={this.props.layoutContent.facetsJSON}
							passedAppFunctions={this.props.passedAppFunctions}
							containsBreadcrumbTrail={containsBreadcrumbTrail}
							excludeFacets={excludeFacets}
						/>
					</div>

					<div className='col-12'>
						<Widgets
							slotNumber={3}
							slotData={this.props.layoutContent.slots['3']}
							facetsJSON={this.props.layoutContent.facetsJSON}
							passedAppFunctions={this.props.passedAppFunctions}
							containsBreadcrumbTrail={containsBreadcrumbTrail}
							excludeFacets={excludeFacets}
						/>
					</div>
				</div>

				<Choose>
					<When test={this.props.passedAppFunctions.siteFormat == 'desktop'}>
						{/* FOR DESKTOP */}
						<div className='row template-row'>
							<div id="sidebar" className={this.state.stickySidebar && document.getElementById("onetrust-policy") ? 'col-sm-2 aside-facets-column sticky-sidebar sidebar-column-short' : this.state.stickySidebar && !document.getElementById("onetrust-policy") ? 'col-sm-2 aside-facets-column sticky-sidebar sidebar-column' : 'col-sm-2 aside-facets-column sidebar-padding-top'} aria-label='sidebar' role='region'>
								<div className='layout-slot layout-slot-four'></div>
								<Widgets
									newArrivalsWidgetIsPresent={newArrivalsWidgetIsPresent}
									shopSaleWidgetIsPresent={shopSaleWidgetIsPresent}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									slotNumber={4}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['4']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
								/>
								<div className='layout-slot layout-slot-five'></div>
								<Widgets
									newArrivalsWidgetIsPresent={newArrivalsWidgetIsPresent}
									shopSaleWidgetIsPresent={shopSaleWidgetIsPresent}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									slotNumber={5}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['5']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
								/>
								<div className='layout-slot layout-slot-six'></div>
								<Widgets
									newArrivalsWidgetIsPresent={newArrivalsWidgetIsPresent}
									shopSaleWidgetIsPresent={shopSaleWidgetIsPresent}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									slotNumber={6}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['6']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
								/>
								<div className='layout-slot layout-slot-seven'></div>
								<Widgets
									newArrivalsWidgetIsPresent={newArrivalsWidgetIsPresent}
									shopSaleWidgetIsPresent={shopSaleWidgetIsPresent}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									slotNumber={7}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['7']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
								/>							
							</div>
							<div className='col-sm-10 results-column'>
								<div className='layout-slot layout-slot-eight'></div>
								<Widgets
									slotNumber={8}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['8']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
								/>
								<div className='layout-slot layout-slot-nine'></div>
								<Widgets
									slotNumber={9}
									containsBreadcrumbTrail={containsBreadcrumbTrail}
									excludeFacets={excludeFacets}
									slotData={this.props.layoutContent.slots['9']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									actualBreadCrumb={actualBreadCrumb}
								/>
							</div>
						</div>
					</When>
					<Otherwise>
						{/* FOR TABLET */}
						<Portal>
							<div className={`FilterPortal ${this.state.showFacetMenu == true ? 'opened' : ''}`}>
								<FilterMenuTablet
									showFacetMenu={this.state.showFacetMenu}
									toggleFacetMenu={this.toggleFacetMenu}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
									layoutContent={this.props.layoutContent}
									newArrivalsWidgetIsPresent={newArrivalsWidgetIsPresent}
									shopSaleWidgetIsPresent={shopSaleWidgetIsPresent}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
								/>
							</div>
						</Portal>

						<div className='row template-row'>
							<div className='col-sm-12 results-column'>
								<Widgets
									slotNumber={8}
									slotData={this.props.layoutContent.slots['8']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
								/>
								{/* tablet filter-sort */}
								<If test={this.props.passedAppFunctions.siteFormat == 'tablet' && this.props.layoutContent.resultListIsPresent == 'false'}>
									<div className='col-3 filter-sort-row noResultsFilter'>
										<button className='btn btn-grey btn-filter' type='button' onClick={this.toggleFacetMenu}>
											Filter<i className='icon i-arrow-down'></i>
										</button>
									</div>
								</If>

								<Widgets
									slotNumber={9}
									slotData={this.props.layoutContent.slots['9']}
									facetsJSON={this.props.layoutContent.facetsJSON}
									passedAppFunctions={this.props.passedAppFunctions}
									resultListIsPresent={this.props.layoutContent.resultListIsPresent}
									toggleFacetMenu={this.toggleFacetMenu}
									toggleSortMenu={this.toggleSortMenu}
									showSortMenu={this.state.showSortMenu}
								/>
							</div>
						</div>
					</Otherwise>
				</Choose>

				{/* <div className={`results-column ${this.props.passedAppFunctions.siteFormat == "desktop" ? 'col-sm-10 col-md-10 col-lg-10' : 'col-sm-12 col-md-12 col-lg-12' }`}>
                    <Widgets slotNumber={8} slotData={this.props.layoutContent.slots['8']} facetsJSON={this.props.layoutContent.facetsJSON} passedAppFunctions={this.props.passedAppFunctions} />
                    <Widgets slotNumber={9} slotData={this.props.layoutContent.slots['9']} facetsJSON={this.props.layoutContent.facetsJSON} passedAppFunctions={this.props.passedAppFunctions} />
                </div> */}
				<If test={this.props.layoutContent.resultListIsPresent == 'true'}>
					<div className='template-row'>
						<div className='recentlyViewedContainer'>
							<ProductRecommendations
								widgetData={{
									zone: 'PDPZ2_RV',
									scrollable: true,
									rows: 1,
									productsPerRow: 6,
									showProductTitle: true,
									title: 'Recently Viewed',
								}}
								passedAppFunctions={this.props.passedAppFunctions}
							/>
						</div>
					</div>
				</If>
			</section>
		);
	}
}

class SelectedFacets extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.openFacet = false;
		this.openCloseFacet = this.openCloseFacet.bind(this);
		this.closeFacet = this.closeFacet.bind(this);
		this.generateLinkForFacet = this.generateLinkForFacet.bind(this);
	}
	generateLinkForFacet(thisFacetValue) {
		return generateLinkForFacetCommon(thisFacetValue, this.props);
	}
	openCloseFacet(facetName) {
		var currentlyOpenFacet = this.state.openFacet;

		if (currentlyOpenFacet) {
			this.state.openFacet = false;
		}
		if (facetName != currentlyOpenFacet) {
			this.state.openFacet = facetName;
		}
		this.setState({});
	}
	closeFacet() {
		this.setState({ openFacet: false });
	}
	selectedFacets() {
		var existingFacets = this.props.passedAppFunctions.getURLParamValue('facet');
		var existingFacetsArray = [];
		var returnFacetArray = [];
		if (existingFacets) {
			existingFacetsArray = existingFacets.split('|');
		}
		for (var i = 0; i < existingFacetsArray.length; i++) {
			if (existingFacetsArray[i].indexOf('dil_shipinternational') == -1) {
				returnFacetArray.push(existingFacetsArray[i]);
			}
		}
		if (this.props.passedAppFunctions.seoColorInfo.seoColorValue) {
			returnFacetArray.push('seoColor');
		}
		return returnFacetArray;
	}
	getFacetNameFromFacetValue(facetValue) {
		var returnString = 'facet';
		try {
			if (facetValue == 'seoColor') {
				return this.props.passedAppFunctions.seoColorInfo.seoColorDisplay;
			}
			var facetName = facetValue.split('%3A')[0];
			if (this.props.facetsJSON[facetName]) {
				if (facetName == 'dil_pricereduced') return 'Sale';
				for (var i = 0; i < this.props.facetsJSON[facetName].values.length; i++) {
					var thisFacetValue = this.props.facetsJSON[facetName].values[i];
					if (
						facetValue ==
						thisFacetValue.val
							.split('%2B')
							.join('%25252B')
							.split('%26')
							.join('%2526').split('%25252B').join('%252B')
					) {
						if (facetName == 'dil_newArrival') return 'New Arrivals';
						return thisFacetValue.dsp;
					}
				}
			} else {
				if (facetName == 'dil_newArrival') return 'New Arrivals';
				if (facetName == 'dil_pricereduced') return 'Sale';
				return facetValue
					.split('%3A')[1]
					.split('%22')
					.join('');
			}
		} catch (e) {
			console.log(e);
		}
		return returnString;
	}
	resetKeepPos = () => {
		this.props.passedAppFunctions.setNavigationToggle('keepPos', false);
	};
	render() {
		return (
			<div id='selectFilterFacets'>
				<If test={this.selectedFacets().length > 0}>
					<span>
						<Link className='clearAllFilters' to={this.generateLinkForFacet(false)} onClick={this.resetKeepPos}>
							clear all
						</Link>
					</span>
					<ForEach var='thisFacetValue' items={this.selectedFacets()}>
						<If test={this.getFacetNameFromFacetValue(thisFacetValue) != 'facet'}>
							<span className='selectedFilter' key={thisFacetValue}>
								<Link to={this.generateLinkForFacet(thisFacetValue)}>
									{this.getFacetNameFromFacetValue(thisFacetValue)
										.split('#double;')
										.join('"')
										.split('%27')
										.join("'")}
									<svg
										role='img'
										aria-labelledby={
											'clearFilter' +
											this.getFacetNameFromFacetValue(thisFacetValue)
												.split('#double;')
												.join('"')
												.split(' ')
												.join('')
										}
										height='10'
										width='10'
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 48.77 48.77'>
										<title
											id={
												'clearFilter' +
												this.getFacetNameFromFacetValue(thisFacetValue)
													.split('#double;')
													.join('"')
													.split(' ')
													.join('')
											}>
											clear filter
										</title>
										<g data-name='Layer 2'>
											<g data-name='Layer 1'>
												<path d='M25.8,24.39,48.48,1.71A1,1,0,0,0,47.07.29L24.39,23,1.71.29A1,1,0,0,0,.29,1.71L23,24.39.29,47.07a1,1,0,1,0,1.41,1.41L24.39,25.8,47.07,48.48a1,1,0,0,0,1.41-1.41Z' />
											</g>
										</g>
									</svg>
								</Link>
							</span>
						</If>
					</ForEach>
				</If>
			</div>
		);
	}
}

class FilterMenuTablet extends React.Component {
	componentDidMount() {
		this._handleFacetClick();
	}
	componentWillUnmount() {
		this._unbindFacetClicks();
	}

	_handleFacetClick() {
		$('.facet-title').on('click.toggle', function(e) {
			$(this).toggleClass('open');
		});
	}
	_unbindFacetClicks() {
		$('.facet-title').off('click.toggle');
	}
	render() {
		return (
			<React.Fragment>
				<aside className={`filter-by-wrapper filter-sort-wrappers ${this.props.showFacetMenu ? 'opened' : ''}`} aria-label='sidebar' role='region'>
					<div className='header-wrapper'>
						<button className='btn btn-secondary' onClick={this.props.toggleFacetMenu}>
							Done
						</button>
					</div>
					<div className='filter-by-content filter-sort-contents'>
						<div className='facet-inner'>
							<SelectedFacets facetsJSON={this.props.facetsJSON} passedAppFunctions={this.props.passedAppFunctions} />
							<Widgets
								newArrivalsWidgetIsPresent={this.props.newArrivalsWidgetIsPresent}
								shopSaleWidgetIsPresent={this.props.shopSaleWidgetIsPresent}
								resultListIsPresent={this.props.resultListIsPresent}
								slotNumber={4}
								slotData={this.props.layoutContent.slots['4']}
								facetsJSON={this.props.facetsJSON}
								passedAppFunctions={this.props.passedAppFunctions}
								toggleFacetMenu={this.props.toggleFacetMenu}
							/>
							<Widgets
								newArrivalsWidgetIsPresent={this.props.newArrivalsWidgetIsPresent}
								shopSaleWidgetIsPresent={this.props.shopSaleWidgetIsPresent}
								resultListIsPresent={this.props.resultListIsPresent}
								slotNumber={5}
								slotData={this.props.layoutContent.slots['5']}
								facetsJSON={this.props.facetsJSON}
								passedAppFunctions={this.props.passedAppFunctions}
							/>
							<Widgets
								newArrivalsWidgetIsPresent={this.props.newArrivalsWidgetIsPresent}
								shopSaleWidgetIsPresent={this.props.shopSaleWidgetIsPresent}
								resultListIsPresent={this.props.resultListIsPresent}
								slotNumber={6}
								slotData={this.props.layoutContent.slots['6']}
								facetsJSON={this.props.facetsJSON}
								passedAppFunctions={this.props.passedAppFunctions}
							/>
							<Widgets
								newArrivalsWidgetIsPresent={this.props.newArrivalsWidgetIsPresent}
								shopSaleWidgetIsPresent={this.props.shopSaleWidgetIsPresent}
								resultListIsPresent={this.props.resultListIsPresent}
								slotNumber={7}
								slotData={this.props.layoutContent.slots['7']}
								facetsJSON={this.props.facetsJSON}
								passedAppFunctions={this.props.passedAppFunctions}
							/>
						</div>
					</div>
				</aside>
				<div className='filterWhiteBG'></div>
				<div className='shade' onClick={this.props.toggleFacetMenu}></div>
			</React.Fragment>
		);
	}
}

export default NavigationTemplate;
