import React from 'react';
import { Widgets } from '../Widgets/Widgets';
import { resizeMap } from '../../../../../../util/imageMaps';
export class ContentTemplate extends React.Component {
	constructor (props) {
		super(props);
	}
	componentDidMount () {
		/**
		 * If content contains a carousel with a useMap on first image, initialize the map.
		 **/

		resizeMap();
		window.addEventListener('load', resizeMap);
		window.addEventListener('resize', _.debounce(resizeMap, 400));
	}
	componentWillUnmount () {
		window.removeEventListener('load', this.resizeMap);
		window.removeEventListener('resize', this.resizeMap);
	}
	render () {
		var fourProductCatalogEntryList = false,
			ContentRecommendation = false,
			fourProductSlotNum = [],
			contentSlotNum = [];
		try {
			if (this.props.layoutContent.slots != undefined) {
				var slotKeysToIterate = Object.keys(this.props.layoutContent.slots);
				for (var i = 0; i < slotKeysToIterate.length; i++) {
					var widgetKeysToIterate = this.props.layoutContent.slots[slotKeysToIterate[i]];

					if (
						widgetKeysToIterate.widgets != undefined &&
						widgetKeysToIterate.widgets.length > 0 &&
						widgetKeysToIterate.widgets[i] != undefined &&
						widgetKeysToIterate.widgets[i].widgetType != undefined &&
						widgetKeysToIterate.widgets[i].widgetType.length > 0
					) {
						var widgetType = widgetKeysToIterate.widgets[i].widgetType;
						if (widgetType != undefined && widgetType === 'FourProductCatalogEntryList') {
							fourProductCatalogEntryList = true;

							fourProductSlotNum.push(i);
						}
					}

					if (
						widgetKeysToIterate.widgets != undefined &&
						widgetKeysToIterate.widgets.length > 0 &&
						widgetKeysToIterate.widgets[i] != undefined &&
						widgetKeysToIterate.widgets[i].widgetType != undefined &&
						widgetKeysToIterate.widgets[i].widgetType.length > 0 &&
						widgetKeysToIterate.widgets[i].content != undefined &&
						widgetKeysToIterate.widgets[i].content.length > 0
					) {
						var widgetType = widgetKeysToIterate.widgets[i].widgetType;
						var content = widgetKeysToIterate.widgets[i].content;
						if (
							widgetType != undefined &&
							widgetType === 'ContentRecommendation' &&
							content != undefined &&
							content.indexOf('category-container') !== -1 &&
							content.indexOf('col-xs-12 col-sm-6 col-md-6 col-lg-3') !== -1
						) {
							ContentRecommendation = true;

							contentSlotNum.push(i);
						}
					}
				}
			}
		} catch (e) {
			console.log(e);
		}

		return (
			<section id='ContentTemplate'>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(0) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(0) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={1}
						slotData={this.props.layoutContent.slots['1']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(1) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(1) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={2}
						slotData={this.props.layoutContent.slots['2']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(2) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(2) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={3}
						slotData={this.props.layoutContent.slots['3']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(3) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(3) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={4}
						slotData={this.props.layoutContent.slots['4']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(4) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(4) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={5}
						slotData={this.props.layoutContent.slots['5']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(5) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(5) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={6}
						slotData={this.props.layoutContent.slots['6']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(6) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(6) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={7}
						slotData={this.props.layoutContent.slots['7']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
				<div
					className={`${(fourProductCatalogEntryList == true && fourProductSlotNum.indexOf(7) >= 0) || (ContentRecommendation == true && contentSlotNum.indexOf(7) >= 0)
						? 'row'
						: ''
						}`}
				>
					<Widgets
						slotNumber={8}
						slotData={this.props.layoutContent.slots['8']}
						passedAppFunctions={this.props.passedAppFunctions}
						contentData={this.props.contentData}
					/>
				</div>
			</section>
		);
	}
}

export default ContentTemplate;
