import React from 'react';
import { Widgets } from '../Widgets/Widgets';
import { ChanelBanner } from '../../../../Shared/Content/ProductDetail/ChanelProductBanner/ChanelProductBanner';
import ChanelHOne from '../Widgets/ChanelHOneHeader/ChanelHOne';


export class ChanelTemplate extends React.Component {
    render() {
        return (
            <section id='ChanelTemplate'>
                <div className='template-row m-0'>
                    <ChanelHOne slotData={this.props.layoutContent.slots['2']} passedAppFunctions={this.props.passedAppFunctions} />
                    <ChanelBanner />
                    <div className='row'>
                    <div className='col-3'>
                        <Widgets slotData={this.props.layoutContent.slots['2']} passedAppFunctions={this.props.passedAppFunctions} />
                    </div>
                    <div className='col-9'>
                            <Widgets slotData={this.props.layoutContent.slots['3']} passedAppFunctions={this.props.passedAppFunctions} />
                            <Widgets slotData={this.props.layoutContent.slots['4']} passedAppFunctions={this.props.passedAppFunctions} />
                            <Widgets slotData={this.props.layoutContent.slots['5']} passedAppFunctions={this.props.passedAppFunctions} />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChanelTemplate;
