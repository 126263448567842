import React from 'react';
import { SetInnerHTML } from '../../../../../../UI/SetInnerHTML/SetInnerHTML';


class ChanelHOne extends React.Component {
    render() {
        var widgetData = this.props.slotData.widgets[0];

        return (
            <div className='chanelHOne col-12 template-row m-0'>
                {
                    widgetData.isChanelHome === 'true' && widgetData.currentCategoryName == '' ?
                    <h1 className='text-center'>CHANEL</h1>
                    :
                    <h1 className='text-center'>CHANEL:&nbsp;&nbsp;<SetInnerHTML innerHTML={widgetData.currentCategoryName} /></h1>
                }
            </div>
        );
    }
}

export default ChanelHOne;
