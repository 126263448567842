import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import Slider from 'react-slick';
import Slider from '../../../../client/customModules/react-slick';
import { getTopPersonalizationInfo } from '../../../util/getTopPersonalizationInfo';

export class ArrowLeftButton extends Component {
	render() {
		return (
			<>
				{this.props.vertical == true ? (
					<div className='arrows-wrap-top'>
						<button onClick={this.props.onClick} className={`prev-top ${this.props.className}`} type='button' data-role='none'>
							<span className='sr-only'>Previous</span>
						</button>
						<button onClick={this.props.onClick} className={`prev-bottom ${this.props.className}`} type='button' data-role='none'>
							<span className='sr-only'>Previous</span>
						</button>
					</div>
				) : (
					<button onClick={this.props.onClick} className={this.props.className} type='button' data-role='none'>
						<span className='sr-only'>Previous</span>
					</button>
				)}
			</>
		);
	}
}

export class ArrowRightButton extends Component {
	render() {
		return (
			<>
				{this.props.vertical == true ? (
					<div className='arrows-wrap-bottom'>
						<button onClick={this.props.onClick} className={`next-top ${this.props.className}`} type='button' data-role='none'>
							<span className='sr-only'>Next</span>
						</button>
						<button onClick={this.props.onClick} className={`next-bottom ${this.props.className}`} type='button' data-role='none'>
							<span className='sr-only'>Next</span>
						</button>
					</div>
				) : (
					<button onClick={this.props.onClick} className={this.props.className} type='button' data-role='none'>
						<span className='sr-only'>Next</span>
					</button>
				)}
			</>
		);
	}
}

export class ProductRecs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			zone: '',
			rec_attributes: [],
			target_header_txt: '',
			priceNumber: '',
			siteFormat: this.props.passedAppFunctions ? this.props.passedAppFunctions.siteFormat : '',
			portrait: false,
			landscape: false,
			atbReady: false,
		};
		this.setRecState = this.setRecState.bind(this);
		this.showRecs = this.showRecs.bind(this);
	}

	setRecState(rec_product_ids, zone, symbolic, target_id, target_category, rec_attributes, target_attributes, target_header_txt, ab_test_id, rec_category_ids) {
		this.addCollectionToFrontOfRecs(rec_attributes);
		this.addOSSToFrontOfRecs(rec_attributes);

		if (rec_attributes && rec_attributes.length > 0) {
			for (var i = 0; i < rec_attributes.length; i++) {
				rec_attributes[i][2] = rec_attributes[i][2]
					.split('/')
					[rec_attributes[i][2].split('/').length - 1].split('.jpg')
					.join('');
			}
			this.setState({
				zone: zone,
				rec_attributes: rec_attributes,
				target_header_txt: target_header_txt,

			});
			if (this.props.gotRecs) {
				this.props.gotRecs();
				this.setState({
					atbReady: true,
				});
			}
		} else {
			if (this.props.noRecs) {
				this.props.noRecs();
			}
		}
	}
	addOSSToFrontOfRecs(rec_attributes) {
		if (this.props.currentOSSAccessories && this.props.currentOSSAccessories.length > 0) {
			for (var i = this.props.currentOSSAccessories.length - 1; i >= 0; i--) {
				var accessory = this.props.currentOSSAccessories[i];
				var attributes = [
					accessory.dil_oss_acc_name,
					`/p/${accessory.dil_oss_acc_keyword}/${accessory.dil_oss_acc_catentry_id}`,
					`http://dimg.dillards.com/is/image/DillardsZoom/${accessory.dil_oss_acc_fullImage}`,
					0.0, //average rating
					'', //brand code
					'', //number reviews
					'', //minPrice
					'', //maxPrice
					'true', //shipInternational
					accessory.dil_oss_acc_catentry_id,
					accessory.dil_oss_acc_dept,
					accessory.dil_oss_acc_mic,
					(accessory.dil_oss_acc_mfname || '').split('&amp;').join('&'),
					accessory.dil_oss_acc_keyword,
				];
				rec_attributes.unshift(attributes);
			}
		}
	}
	addCollectionToFrontOfRecs(rec_attributes) {
		if (this.props.currentCollection && this.props.currentCollection.length > 0) {
			for (var i = this.props.currentCollection.length - 1; i >= 0; i--) {
				var accessory = this.props.currentCollection[i];
				var attributes = [
					accessory.name, //name
					`/p/${accessory.keyword}/${accessory.catentryId}`, //URL
					accessory.imageName,
					0.0, //average rating
					'', //brand code
					'', //number reviews
					'', //minPrice
					'', //maxPrice
					'true', //shipInternational
					accessory.catentryId, //catentryId
					accessory.dept,
					accessory.mic,
					accessory.brandName,
					accessory.keyword,
				];
				rec_attributes.unshift(attributes);
			}
		}
	}
	imgSourceFromAttr(thisAttr) {
		return `https://dimg.dillards.com/is/image/DillardsZoom/${this.props.passedAppFunctions.siteFormat == 'mobile' ? 'nav2' : 'productRec'}/${thisAttr[13]}/${
			thisAttr[2].split('dimg').length > -1
				? thisAttr[2]
						.split('/')
						[thisAttr[2].split('/').length - 1].split('.jpg')
						.join('')
				: thisAttr[2]
		}.jpg`;
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.contentData && this.props.contentData.productId != nextProps.contentData.productId) {
			return true;
		}
		if (!_.isEqual(nextState, this.state)) {
			return true;
		}
		return false;
	}

	render() {
		var zoneNameMap = {
			PDPZ1: {
				title: 'you may also like',
				arrowsNum: 4,
			},
			PDPZ2: {
				title: 'Recently Viewed',
				arrowsNum: 6,
			},
			PDPZ3: {
				title: 'you may also like',
				arrowsNum: 4,
			},
			JFYZ1: {
				title: 'Just For You',
				arrowsNum: 6,
			},
			ACMZ1: {
				title: 'you may also like',
				arrowsNum: 3,
			},
			Fallback: {
				title: 'you may also like',
				arrowsNum: 4,
			},
		};
		var zoneNameInfo = zoneNameMap[this.props.zoneName] || zoneNameMap['Fallback'],
			arrows = zoneNameInfo.arrowsNum < this.state.rec_attributes.length,
			vertical = this.props.vertical || false;
			
		if (this.state.siteFormat == 'tablet') {
			switch (this.props.zoneName) {
				case 'PDPZ1':
				case 'PDPZ3':
					if (this.state.portrait || this.props.contentData.productLayout == 'BundleDisplay' || this.props.contentData.productLayout == 'OutfitDisplay') {
						arrows = false;
						vertical = false;
					} else {
						vertical = true;
					}
					break;
				case 'PDPZ2':
					arrows = false;
				default:
					break;
			}
		}

		var slidesToShow = this.props.slidesToShow || 4;
		if (this.state.siteFormat == 'tablet') {
			var slidesToShow = 4;
			slidesToShow += 0.5;
		}

		var settings = {
			dots: false,
			infinite: false,
			vertical: vertical,
			speed: 500,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			swipe: true,
			swipeToSlide: true,
			lazyLoad: true,
			arrows: arrows,
			prevArrow: <ArrowLeftButton vertical={vertical} />,
			nextArrow: <ArrowRightButton vertical={vertical} />,
			zoneName: this.props.zoneName,
			responsive: this.props.passedAppFunctions
				? (this.state.siteFormat == 'tablet' &&
						this.props.passedAppFunctions.modalInfo &&
						this.props.passedAppFunctions.modalInfo.modalType == 'AddtoCartModal') ||
				  (this.state.siteFormat == 'tablet' &&
						this.props.passedAppFunctions.modalInfo &&
						this.props.passedAppFunctions.modalInfo.modalType == 'InventoryModal')
					? [
							{
								breakpoint: 1367,
								settings: {
									slidesToShow: 2.5,
									slidesToScroll: 1,
									swipeToSlide: true,
									vertical: vertical,
									arrows: false,
								},
							},
					  ]
					: this.state.siteFormat == 'tablet' && this.props.addToBagModal == 'false'
					? [
							{
								breakpoint: 1025,
								settings: {
									slidesToShow: 4.5,
									slidesToScroll: 1,
									swipeToSlide: true,
									vertical: vertical,
									arrows: arrows,
								},
							},
					  ]
					: ''
				: '',
		};

		var settingsStayVertical = {
			dots: false,
			infinite: false,
			vertical: vertical || false,
			speed: 500,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			swipe: true,
			lazyLoad: true,
			arrows: zoneNameInfo.arrowsNum < this.state.rec_attributes.length,
			zoneName: this.props.zoneName,
		};

		slidesToShow = 2.5;

		var mobileSettings = {
			infinite: false,
			slidesToShow: slidesToShow,
			slidesToScroll: 2,
			vertical: false,
			arrows: 2 < this.state.rec_attributes.length,
		};

		return (
			<If test={this.props.passedAppFunctions.wasParms.useProductRecommendations == 'true'}>
				{this.props.stayVertical &&
				this.state.siteFormat != 'mobile' &&
				this.state.siteFormat == 'tablet' &&
				!this.props.contentData.productLayout == 'BundleDisplay' &&
				!this.props.contentData.productLayout == 'OutfitDisplay' ? (
					//Not Responsive
					<aside className={`productRecs ml-auto ${settingsStayVertical.arrows ? 'has-arrows' : ''}`}>
						{this.state.target_headers_txt && this.state.target_header_txt.length ? <span className='pr-title'>{this.state.target_header_txt}</span> : ''}
						{this.state.rec_attributes ? (
							<div>
								{
									zoneNameInfo.title == 'you may also like' ? (
										<h6 className='productRecs__title'>you may also like</h6>
									) : zoneNameInfo.title == 'Recently Viewed' ? (
										<h6 className='recentlyViewed'>
											<span className='recently-viewed-title'>Recently Viewed</span>
										</h6>
									) : zoneNameInfo.title == 'Just For You' ? (
										<h6 className='justForYou'>
											<span className='recently-viewed-title'>Just For You</span>
										</h6>
									) : (
										''
									) //  blank if no title specified in zoneNameMap object
								}
								{this.state.siteFormat == 'desktop' ? (
									<Slider {...settingsStayVertical}>
										{this.state.rec_attributes.map((attr, index) => (
											<figure className='item' key={attr[2] + index}>
												<Link
													to={{
														pathname: attr[1],
														state: {
															qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
															categoryId: this.props.zoneName,
														},
													}}
													onClick={() => window.triggerTargetInteraction(attr[14])}
												>
													<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
												</Link>
												<Link
													to={{
														pathname: attr[1],
														state: {
															qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
															categoryId: this.props.zoneName,
														},
													}}
													onClick={() => window.triggerTargetInteraction(attr[14])}
													className='item-title'
												>
													{attr[12].split('&trade;').join('™').split('#double;').join('"')}
												</Link>
											</figure>
										))}
									</Slider>
								) : (
									<div>
										{this.state.rec_attributes.map((attr, index) => (
											<figure className='item' key={attr[2] + index}>
												<Link
													to={{
														pathname: attr[1],
														state: {
															qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
															categoryId: this.props.zoneName,
														},
													}}
													onClick={() => window.triggerTargetInteraction(attr[14])}
												>
													<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
												</Link>
												<Link
													to={{
														pathname: attr[1],
														state: {
															qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
															categoryId: this.props.zoneName,
														},
													}}
													onClick={() => window.triggerTargetInteraction(attr[14])}
													className='item-title'
												>
													{attr[12].split('&trade;').join('™').split('#double;').join('"')}
												</Link>
											</figure>
										))}
									</div>
								)}
							</div>
						) : (
							''
						)}
					</aside>
				) : !this.props.stayVertical && this.state.siteFormat != 'mobile' ? (
					//Responsive
					<aside className={`productRecs ml-auto ${settings.arrows ? 'has-arrows' : ''}`}>
						{this.state.target_headers_txt && this.state.target_header_txt.length ? <span className='pr-title'>{this.state.target_header_txt}</span> : ''}
						{this.state.rec_attributes && this.state.rec_attributes.length > 3 ? (
							<div>
								{
									zoneNameInfo.title == 'you may also like' ? (
										<h6 className='productRecs__title'>you may also like</h6>
									) : zoneNameInfo.title == 'Recently Viewed' ? (
										<h6 className='recentlyViewed'>
											<span className='recently-viewed-title'>Recently Viewed</span>
										</h6>
									) : zoneNameInfo.title == 'Just For You' ? (
										<h6 className='justForYou'>
											<span className='recently-viewed-title'>Just For You</span>
										</h6>
									) : (
										''
									) //  blank if no title specified in zoneNameMap object
								}

								{/* ATB recs in a vertical css scroller */}
								{this.state.rec_attributes &&
								this.state.rec_attributes.length &&
								(document.querySelectorAll('.AddtoCartModal').length > 0 || document.querySelectorAll('.InventoryModal').length > 0) ? (
									this.state.rec_attributes && this.state.rec_attributes.length ? (
										<div id='scrollingAtbRecs'>
											{this.state.rec_attributes.map((attr, index) => (
												<figure className='item' key={attr[2] + index}>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
													>
														<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
													</Link>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
														className='item-title'
													>
														{attr[12].split('&trade;').join('™').split('#double;').join('"')}
													</Link>
												</figure>
											))}
										</div>
									) : (
										''
									)
								) : (
									<Slider {...settings}>
										{this.state.rec_attributes && this.state.rec_attributes.length
											? this.state.rec_attributes.map((attr, index) => (
													<figure className='item' key={attr[2] + index}>
														<Link
															to={{
																pathname: attr[1],
																state: {
																	qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																	categoryId: this.props.zoneName,
																},
															}}
															onClick={() => window.triggerTargetInteraction(attr[14])}
														>
															<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
														</Link>
														<Link
															to={{
																pathname: attr[1],
																state: {
																	qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																	categoryId: this.props.zoneName,
																},
															}}
															onClick={() => window.triggerTargetInteraction(attr[14])}
															className='item-title'
														>
															{attr[12].split('&trade;').join('™').split('#double;').join('"')}
														</Link>
													</figure>
											  ))
											: ''}
									</Slider>
								)}
							</div>
						) : this.state.rec_attributes && this.state.rec_attributes.length <= 3 ? (
							<div>
								{this.state.rec_attributes && this.state.rec_attributes.length ? (
									zoneNameInfo.title == 'you may also like' ? (
										<h6 className='productRecs__title'>you may also like</h6>
									) : zoneNameInfo.title == 'Recently Viewed' ? (
										<h2 className='recentlyViewed'>
											<span className='recently-viewed-title'>Recently Viewed</span>
										</h2>
									) : zoneNameInfo.title == 'Just For You' ? (
										<h2 className='justForYou'>
											<span className='recently-viewed-title'>Just For You</span>
										</h2>
									) : (
										''
									) //  blank if no title specified in zoneNameMap object
								) : (
									''
								)}
								<Slider {...settings}>
									{this.state.rec_attributes && this.state.rec_attributes.length
										? this.state.rec_attributes.map((attr, index) => (
												<figure className='item' key={attr[2] + index}>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
													>
														<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
													</Link>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
														className='item-title'
													>
														{attr[12].split('&trade;').join('™').split('#double;').join('"')}
													</Link>
												</figure>
										  ))
										: ''}
								</Slider>
							</div>
						) : (
							''
						)}
					</aside>
				) : this.state.siteFormat == 'mobile' ? (
					//For Mobile
					<aside className={`productRecs ${mobileSettings.arrows ? 'has-arrows' : ''}`}>
						{this.state.target_headers_txt && this.state.target_header_txt.length ? <span className='pr-title'>{this.state.target_header_txt}</span> : ''}
						{this.state.rec_attributes && this.state.rec_attributes.length > 2 && this.state.siteFormat == 'mobile' ? (
							<div>
								{
									zoneNameInfo.title == 'you may also like' ? (
										<h6 className='productRecs__title'>you may also like</h6>
									) : zoneNameInfo.title == 'Recently Viewed' ? (
										<h6 className='recentlyViewed'>
											<span className='recently-viewed-title'>Recently Viewed</span>
										</h6>
									) : zoneNameInfo.title == 'Just For You' ? (
										<h6 className='justForYou'>
											<span className='recently-viewed-title'>Just For You</span>
										</h6>
									) : (
										''
									) //  blank if no title specified in zoneNameMap object
								}

								{/* ATB product recs in a vertical scroller */}
								{this.state.rec_attributes &&
								this.state.rec_attributes.length &&
								(document.querySelectorAll('.AddtoCartModal').length > 0 || document.querySelectorAll('.InventoryModal').length > 0) ? (
									this.state.rec_attributes && this.state.rec_attributes.length ? (
										<div id='scrollingAtbRecs'>
											{this.state.rec_attributes.map((attr, index) => (
												<figure className='item' key={attr[2] + index}>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
													>
														<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
													</Link>
													<Link
														to={{
															pathname: attr[1],
															state: {
																qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																categoryId: this.props.zoneName,
															},
														}}
														onClick={() => window.triggerTargetInteraction(attr[14])}
														className='item-title'
													>
														{attr[12].split('&trade;').join('™').split('#double;').join('"')}
													</Link>
												</figure>
											))}
										</div>
									) : (
										''
									)
								) : (
									<Slider {...mobileSettings}>
										{this.state.rec_attributes && this.state.rec_attributes.length
											? this.state.rec_attributes.map((attr, index) => (
													<figure className='item' key={attr[2] + index}>
														<Link
															to={{
																pathname: attr[1],
																state: {
																	qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																	categoryId: this.props.zoneName,
																},
															}}
															onClick={() => window.triggerTargetInteraction(attr[14])}
														>
															<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
														</Link>
														<Link
															to={{
																pathname: attr[1],
																state: {
																	qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
																	categoryId: this.props.zoneName,
																},
															}}
															onClick={() => window.triggerTargetInteraction(attr[14])}
															className='item-title'
														>
															{attr[12].split('&trade;').join('™').split('#double;').join('"')}
														</Link>
													</figure>
											  ))
											: ''}
									</Slider>
								)}
							</div>
						) : this.state.rec_attributes && this.state.rec_attributes.length < 3 && this.state.siteFormat == 'mobile' ? (
							<div>
								{this.state.rec_attributes && this.state.rec_attributes.length ? (
									zoneNameInfo.title == 'you may also like' ? (
										<h6 className='productRecs__title'>you may also like</h6>
									) : zoneNameInfo.title == 'Recently Viewed' ? (
										<h6 className='recentlyViewed'>
											<span className='recently-viewed-title'>Recently Viewed</span>
										</h6>
									) : zoneNameInfo.title == 'Just For You' ? (
										<h6 className='justForYou'>
											<span className='recently-viewed-title'>Just For You</span>
										</h6>
									) : (
										''
									) //  blank if no title specified in zoneNameMap object
								) : (
									''
								)}

								{this.state.rec_attributes && this.state.rec_attributes.length
									? this.state.rec_attributes.map((attr, index) => (
										<figure className='item' key={attr[2] + index}>
											<Link
												to={{
													pathname: attr[1],
													state: {
														qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
														categoryId: this.props.zoneName,
													},
												}}
												onClick={() => window.triggerTargetInteraction(attr[14])}
											>
												<img src={this.imgSourceFromAttr(attr)} alt={attr[0].split('&trade;').join('™')} />
											</Link>
											<Link
												key={attr[2] + index}
												to={{
													pathname: attr[1],
													state: {
														qv: attr[0].toLowerCase().indexOf('chanel') == -1 && attr[0].toLowerCase().indexOf('egift') == -1,
														categoryId: this.props.zoneName,
													},
												}}
												onClick={() => window.triggerTargetInteraction(attr[14])}
												className='item-title'
											>
												{attr[12].split('&trade;').join('™').split('#double;').join('"')}
											</Link>
										</figure>
									  ))
									: ''}
							</div>
						) : (
							''
						)}
					</aside>
				) : (
					''
				)}
			</If>
		);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.contentData && this.props.contentData.productId != nextProps.contentData.productId) {
			this.setState({
				zone: '',
				rec_attributes: '',
				target_header_txt: '',
				priceNumber: '',
			});
			this.setRecRequestData(this.generateBundlePartnumbers(nextProps) || nextProps.contentData.partNumber);
		}
	}

	setOrientationState_iOS = () => {
		var portrait = false,
			landscape = false;
		if (window.innerHeight > window.innerWidth) portrait = true;
		else landscape = true;
		this.setState({
			portrait: portrait,
			landscape: landscape,
		});
	};

	setOrientationState_chrome = () => {
		var portrait = false,
			landscape = false;
		if ($(window).width() < $(window).height()) portrait = true;
		else landscape = true;
		this.setState({
			portrait: portrait,
			landscape: landscape,
		});
	};

	componentDidMount() {
		$(window).on('showRecs', this.showRecs);
		this.setRecRequestData();
		if (this.state.siteFormat == 'tablet') {
			// Using window.innerWidth for Tablet. IOS 10 was returning window.outerWidth = 0
			if (navigator.userAgent.match('CriOS')) {
				this.setOrientationState_chrome();
				window.addEventListener(
					'resize',
					_.debounce(
						function() {
							this.setOrientationState_chrome();
						}.bind(this),
						false
					)
				);
			} else {
				this.setOrientationState_iOS();
				window.addEventListener(
					'resize',
					_.debounce(
						function() {
							this.setOrientationState_iOS();
						}.bind(this),
						false
					)
				);
			}
		}
	}

	generateBundlePartnumbers(passedProps) {
		var returnString = '';
		var propsToUse = passedProps || this.props;
		if (propsToUse.contentData && (propsToUse.contentData.productLayout == 'BundleDisplay' || propsToUse.contentData.productLayout == 'BundleDisplay')) {
			for (var i = 0; i < propsToUse.contentData.components.length; i++) {
				if (returnString.length > 0) returnString += '|';
				returnString += propsToUse.contentData.components[i].partNumber;
			}
		}
		return returnString;
	}

	showRecs(){
		//triggered from adobe launch rule
		this.formatRecContent((this.props.zoneName) ? this.props.zoneName : '');
	}

	setRecRequestData(nextPartNumber) {
		var zone = (this.props.zoneName) ? this.props.zoneName : '';
		if(this.props.passedAppFunctions.wasParms.useProductRecommendations == 'true' && this.props.passedAppFunctions.wasParms.adobeRecsOffByZone.indexOf(zone) == -1){
			try {
				var partNumber = nextPartNumber || this.generateBundlePartnumbers() || this.props.partNumber || (this.props.contentData && this.props.contentData.partNumber);
					partNumber = (partNumber != '') ? partNumber : null;
				var pageUrl = window.location.href.split('?')[0];
				var topPersonalizationInfo = getTopPersonalizationInfo('ALL');
				var recData = this.getRecData();

				//setting all rec data into a window scoped variable for adobe sdk migration
				window.recCallData = window.recCallData ? window.recCallData : [];
				if(recCallData.length > 0){
					if(recCallData[0].url != pageUrl){
						window.recCallData = [];
						recCallData.push({"url" : pageUrl});
					}
				}else{
					recCallData.push({"url" : pageUrl});
				}
				
				// var potentialCategoryId = '';
				// try{
				// 	potentialCategoryId = this.props.passedAppFunctions.getDataLayerInfo().productListItems[0]._additionalVars.primaryCategoryId;
				// }catch(e){
				// 	console.log(e);
				// }

				var thisData = {};
                thisData.zone = zone;
                thisData.category = (this.props.passedAppFunctions.getURLParamValue('categoryId')) ? this.props.passedAppFunctions.getURLParamValue('categoryId') : (this.props.passedAppFunctions.linkToState && this.props.passedAppFunctions.linkToState.categoryId) ?  this.props.passedAppFunctions.linkToState.categoryId : '-10035';
                thisData.brand = (recData && recData.brand) ? recData.brand : '';
                thisData.partNumber = (partNumber && partNumber.indexOf('|') > -1) ? partNumber.split('|')[0] : partNumber;
                thisData.url = pageUrl;
                thisData.productClass = (recData && recData.productClass) ? recData.productClass : '';
                thisData.mic = (recData && recData.mic) ? recData.mic : '';
                thisData.dept = (recData && recData.dept) ? recData.dept : '';
                thisData.decade = (recData && recData.decade) ? recData.decade : '';
                thisData.century = (recData && recData.century) ? recData.century : '';
                thisData.group = (recData && recData.group) ? recData.group : '';
                thisData.name = (recData && recData.name) ? recData.name : '';
                thisData.newArrival = (recData && recData.newArrival) ? recData.newArrival : '';
                thisData.favoriteBrand = (topPersonalizationInfo && topPersonalizationInfo.favoriteBrand) ? topPersonalizationInfo.favoriteBrand : '';
                thisData.favoriteCategoryId = (topPersonalizationInfo && topPersonalizationInfo.favoriteSuperCat) ? topPersonalizationInfo.favoriteSuperCat : '';
				thisData.includeWithRecData = true;
                recCallData.push({'ProductRecs' : thisData});                
			
			} catch (e) {
				if (this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(e);
			}
		}
        if (!(this.props.contentData && this.props.contentData.productLayout && this.props.contentData.productLayout == 'ProductDisplay')) {
        	try { $(document).trigger('addToCart'); } catch (e) {}
        }		
	}

	formatRecContent(zone){
        try{
            if(window.recContent){
				for(var i = 0; i < window.recContent.length; i++){
					var thisContent = window.recContent[i];
                    if(thisContent.scope === zone){

						var recInteractionData = {};
						if(thisContent &&
						   thisContent.rawData &&
						   thisContent.rawData.id &&
						   thisContent.rawData.scope &&
						   thisContent.rawData.scopeDetails){
								recInteractionData.recId = thisContent.rawData.id;
								recInteractionData.recZone = thisContent.rawData.scope;
								recInteractionData.recScopeDetails = thisContent.rawData.scopeDetails;
						}

						var responseData = JSON.parse(thisContent.content);
						var rec_attributes = [];
						
						if(this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(responseData.recommendedItems);
		
						for(var key in responseData.recommendedItems){
							var thisRec = responseData.recommendedItems[key];
							
							var attrs = [
									 thisRec.name
									,(thisRec.pageurl.indexOf('dillards.com') > -1) ? thisRec.pageurl.split('dillards.com')[1] : ''
									,(thisRec.imgurl.indexOf('/') > -1) ? thisRec.imgurl.split('/')[thisRec.imgurl.split('/').length -1].split('.jpg').join('') : ''
									,0.0 //average rating
									,'' //brand code
									,'' //number reviews
									,'' //minPrice
									,'' //maxPrice
									,'true' //shipInternational
									,(thisRec.pageurl.indexOf('/') > -1) ? thisRec.pageurl.split('/')[thisRec.pageurl.split('/').length -1] : ''
									,thisRec.dpt
									,thisRec.mic
									,thisRec.brand
									,thisRec.keyword
									,(recInteractionData) ? recInteractionData : {}
								];
							
							if(attrs[0].indexOf('$entity') == -1 && attrs[1].length > 0 && attrs[2].length > 0 && attrs[9].length > 0 && attrs[12].length > 0 && attrs[13].length > 0){
								rec_attributes.push(attrs);
							}
						}
						this.setRecState("rec_product_ids", zone, "symbolic", "target_id", "target_category", rec_attributes, "target_attributes", "target_header_txt", "ab_test_id", "rec_category_ids");
						window.recContent.splice(i,1);
					}
				}
            }
        }catch(er){
			if(this.props.passedAppFunctions.wasParms.adobeRecsDebug && this.props.passedAppFunctions.wasParms.adobeRecsDebug == 'true') console.log(er);
        }
    }

	getRecData(){
		var recData = {};

		if(this.props.passedAppFunctions && this.props.passedAppFunctions.modalInfo && Object.keys(this.props.passedAppFunctions.modalInfo).length > 0){
			if(this.props.passedAppFunctions.modalInfo.recJSON && this.props.passedAppFunctions.modalInfo.recJSON.promotedDetails){
				var thisRec = this.props.passedAppFunctions.modalInfo.recJSON.promotedDetails[0];
				recData.brand = (thisRec.brand) ? thisRec.brand : '';
				recData.productClass = (thisRec.productClass) ? thisRec.productClass : '';
				recData.mic = (thisRec.mic) ? thisRec.mic : '';
				recData.dept = (thisRec.dept) ? thisRec.dept : '';
				recData.decade = (thisRec.dept) ? thisRec.dept.slice(0,thisRec.dept.length -1) + 'X' : '';
				recData.century = (thisRec.dept) ? thisRec.dept.slice(0,thisRec.dept.length -2) + 'XX' : '';
				recData.group = (thisRec.group) ? thisRec.group : '';
				recData.name = (thisRec.name) ? thisRec.name : '';
				recData.newArrival = (thisRec.newArrival) ? thisRec.newArrival : '';
			}
		}else if(this.props.contentData){
			recData.brand = (this.props.contentData.brandNameForTitle) ? this.props.contentData.brandNameForTitle : '';
			recData.productClass = (this.props.contentData.dceProductClass) ? this.props.contentData.dceProductClass : '';
			recData.mic = (this.props.contentData.mic) ? this.props.contentData.mic : '';
			recData.dept = (this.props.contentData.dept) ? this.props.contentData.dept : '';
			recData.decade = (this.props.contentData.dept) ? this.props.contentData.dept.slice(0,this.props.contentData.dept.length -1) + 'X' : '';
			recData.century = (this.props.contentData.dept) ? this.props.contentData.dept.slice(0,this.props.contentData.dept.length -2) + 'XX' : '';
			recData.group = (this.props.contentData.dceGroup) ? this.props.contentData.dceGroup : '';
			recData.name = (this.props.contentData.productName) ? this.props.contentData.productName : '';
			recData.newArrival = (this.props.contentData.newArrival) ? this.props.contentData.newArrival : '';
		}
		return recData;
	}

	componentWillUnmount() {
		//just to clean up logs and noop function calls.
		window[`${this.props.zoneName}_zp`] = function() {};
		$(window).off('showRecs', this.showRecs);
	}
}
