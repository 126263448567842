import React from 'react';
import { Link } from 'react-router-dom';
export class ChanelBanner extends React.Component {
    render() {
        return (
            
            <div className='col-12 p-0'>
                <div className="chanelBanner">
                    <Link to="/brand/Chanel">
                        <img src=
                        "/assets/img/logos/chanelBannerLogo.png" alt="Chanel"/>
                    </Link>
                </div>
            </div>
            
        );
    }
}

export default ChanelBanner;
